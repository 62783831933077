import Header from "../layout/Header"
import {Link, useNavigate} from 'react-router-dom'
import CardCombo from "./components/CardCombo"
import ListaCombo from "./components/ListaCombo"
import { useState, useEffect } from 'react';
import firebase from "firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button02 from "./components/Button02";
import Credito from "./components/Credito";

const CombosPromo = ({uid})=>{
    const navigate = useNavigate();
    const db = firebase.firestore();
    const [promo, setPromo] = useState([]);
    const [btn_state, setBtn_state] = useState(false)

    useEffect(()=>{
        db.collection('promo').orderBy('timestamp').onSnapshot(function(snapshot){
            setPromo(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])
    const avaliablePromos = promo.filter(promo => promo.data.disponibilidade === true)

    const openCombo = (par)=>{
        document.getElementById(par).style.width = '100%';
    }
    const closeModal = (par)=>{
        document.getElementById(par).style.width = '0%';
    }
    const selectCombo = (e, par)=>{
        e.preventDefault();
        alert(par)
        document.getElementById('modal_pizzas').style.width = '0%';
    }
    const redirect=()=>{
        navigate('/pedido')
    }
    const includeOnCart = (e, par1, par2) => {
        e.preventDefault();
        db.collection('users').doc(uid).collection('carrinho').add({
          idUser: uid,
          produto: par1,
          quantidade: 1,
          valor: par2,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(()=>{
          //voltar para a home
          toast.success('Inserindo este combo ao seu pedido...')
          setTimeout(redirect, 4000);
          setBtn_state(true)
        })
      }


    return(
        <div className="clear_background">
            <ToastContainer theme="dark" position="top-center" />
            <div className="clear_background_container">
                <Header uid={uid}/>

                <div className="container_comum">
                    <div className="combos_promo">
                    
                    <div className="tittle_01">
                   <Link to="/pedido"><i className="bi bi-arrow-left-circle-fill"></i></Link><h4><i className="bi bi-bag-heart"></i>Combos e Promoções</h4><div></div>
                    </div>
                    {avaliablePromos.map((val)=>{
                        return (
                            <div key={val.id}>
                            <div className='modal_combo' id={'combo'+val.id}>
                                <ListaCombo
                                    closeModal={closeModal}
                                    comboId={'combo'+val.id}
                                    nome={val.data.nomePromo}
                                    info={val.data.contPromo}
                                    img={val.data.image}
                                    action={(e)=>includeOnCart(e, val.data.nomePromo, val.data.valor)}
                                    valor={val.data.valor}
                                    btn_state={btn_state}
                                />
                            </div>
                            <CardCombo
                                nome={val.data.nomePromo}
                                info={val.data.contPromo}
                                img={val.data.image}
                                action={()=>openCombo('combo'+val.id)}
                                valor={val.data.valor}
                                />
                            </div>
                            
                        )
                    })}
                    <Button02 value="Finalizar o Pedido" to="/finalizar-pedido" />
                    <Button02 value="Continuar Comprando" to="/pedido" />
                    <Credito />   
                    </div>           
                </div>
            </div>
        </div>
        )
    
}
export default CombosPromo