const ChooseDuo = (props)=>{
    return(
        <div className="choose_duo">
					<input id={props.value01} type="radio" name={props.name} onChange={()=>props.action(props.value01)}/>
					<label htmlFor={props.value01}>{props.value01}</label>
					<input id={props.value02} type="radio" name={props.name} value={props.value02} onChange={(e)=>props.action(props.value02)}/>
					<label htmlFor={props.value02}>{props.value02}</label>
		</div>
        )
}
export default ChooseDuo