import { Link } from "react-router-dom"

//onClick={(e)=>props.action(e , props.nome, props.valor)}

const Button01 = (props)=>{
    return(
        <div className='btn01'>
			<Link to={props.to}><button onClick={props.action} style={{marginTop:'0px'}} disabled={props.btn_state}>{props.value}</button></Link>
		</div>
    )
}

export default Button01