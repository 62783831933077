import Header from "../layout/Header"
import { Link, useNavigate } from "react-router-dom";
import Resumo from './components/Resumo'
import ChooseDuo from './components/ChooseDuo'
import Sabores from './components/Sabores'
import Sabor from './components/Sabor'
import Select from "./components/Select"
import Button02 from './components/Button02'
import Credito from './components/Credito'
import ListaPizzas from "./components/ListaPizzas"
import { CiPizza } from 'react-icons/ci';
import firebase from "firebase";
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PizzasDoces = ({uid})=>{
    const colecao = 'pizzasdoces'
    const navigate = useNavigate();
    const colecaoBorder = 'bordaDoce'
    const db = firebase.firestore();
    const [tipo, setTipo] = useState(null);
    const [sabor01, setSabor01] = useState({
        sabor:'Escolha o primeiro sabor',
        precob: 0,
        precog: 0,
    });
    const [sabor02, setSabor02] = useState({
        sabor:'Escolha o segundo sabor',
        precob: 0,
        precog: 0,
    });
    const [i, setI] = useState(null);
    const [size, setSize] = useState(null);
    const [border, setBorder] = useState('Simples')
    const [price, setPrice] = useState(0);
    const [borderPrices, setBorderPrices] = useState([])
    const [borderPrice, setBorderPrice] = useState(0)
    const [btn_state, setBtn_state] = useState(false)

    const openPizzas = (par)=>{
        setI(par);
        document.getElementById('modal_pizzas').style.width = '100%';
    }
    const selectPizza = (e, par, l, par1, par2)=>{
        e.preventDefault();
        {l === 1
        ?(setSabor01({
            sabor: par,
            precog: par1,
            precob: par2,
        }))
        :(setSabor02({
            sabor: par,
            precog: par1,
            precob: par2,
        }))}
        document.getElementById('modal_pizzas').style.width = '0%';
    }
    const setarTipo = (par)=>{
        setTipo(par)
        setSize(null)
        setBorderPrice(0)
        setBorder('Simples')
    }
    const setarSize = (par)=>{
        setSize(par)
    }
    const setarBorda = (par)=>{
        setBorder(par)
    }
    const redirect=()=>{
        navigate('/pedido')
    }
    useEffect(()=>{
        db.collection(colecaoBorder).where('recheio',"==",border).onSnapshot(function(snapshot){
            setBorderPrices(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
            }))
        })
        /**/
    },[border])
    
    useEffect(()=>{
        {borderPrices.length > 0
            ?setBorderPrice(borderPrices[0].data.valorBorda)
            :setBorderPrice(0)}
    },[borderPrices])

    useEffect(()=>{
        {tipo === 'Meio a Meio' & size === "Broto"
        ?(setPrice(parseFloat((sabor01.precob + sabor02.precob)/2 +(borderPrice / 2))))
        :tipo === 'Meio a Meio' & size === "Grande"
        ?(setPrice(parseFloat((sabor01.precog + sabor02.precog)/2 + borderPrice)))
        :tipo === 'Inteira' & size === "Grande"
        ?(setPrice(parseFloat(sabor01.precog + borderPrice)))
        :tipo === 'Inteira' & size === "Broto"
        ?(setPrice(parseFloat(sabor01.precob + (borderPrice / 2))))
        :(setPrice(0))}
    },[size, borderPrice, sabor01, sabor02])

    const includeOnCart = (e) => {
        e.preventDefault();
        
        let produto = tipo === 'Inteira' ? 'Pizza '+size+' de '+sabor01.sabor : tipo === 'Meio a Meio' ? 'Pizza '+size+' meia '+sabor01.sabor+' / meia '+sabor02.sabor : 'não selecionado';
        let conteudo = tipo === 'Inteira' ? document.getElementById('observacao2').value : document.getElementById('observacao1').value ;
        let observacao = conteudo !== "" ? " - OBSERVAÇÃO: "+conteudo : "" ;
        let tipoBorda = border !== 'Simples' ? ' + Borda de '+border : "";
  
        db.collection('users').doc(uid).collection('carrinho').add({
          idUser: uid,
          produto: produto+tipoBorda+observacao,
          quantidade: 1,
          valor: price,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(()=>{
          //voltar para a home
          toast.success('A pizza foi inserida ao seu pedido...')
          setTimeout(redirect, 4000);
          setBtn_state(true)
        })
      }

    return(
        <div className="clear_background">
            <ToastContainer theme="dark" position="top-center" />
            <div className="clear_background_container">
                <Header uid={uid}/>

                <div className='modal_pizzas' id="modal_pizzas">
                    <ListaPizzas selectPizza={selectPizza} l={i} colecao={colecao} />
                </div>

                <div className="container_comum">
                <div className="container_pizza">
                <div className="tittle_01">
                    <Link to="/pedido"><i className="bi bi-arrow-left-circle-fill"></i></Link>
                    
                    <h5 style={{color:'#442200'}}><CiPizza /> Pizzas doces</h5>
                    
                    <div></div>
                
                </div>

                    <h4>Defina a quantidade de sabores.</h4>
                    <ChooseDuo value01='Inteira' value02='Meio a Meio' name='sabores' action={setarTipo}/>

                    {tipo === "Meio a Meio"
                    ?(<><h4>Defina os sabores.</h4>
                    <Sabores action={openPizzas} sabor01={sabor01.sabor} sabor02={sabor02.sabor}/>
                    {sabor01.sabor !== 'Escolha o primeiro sabor' & sabor02.sabor !== 'Escolha o segundo sabor'
                    ?(<>
                        <h4>Defina o Tamanho.</h4>
                        <ChooseDuo value01='Grande' value02='Broto' name='tamanho' action={setarSize}/>
                        {size != null
                        ?(<>
                            <h4>Defina o tipo de borda.</h4>
                            <Select setarBorda={setarBorda} colecaoBorder={colecaoBorder}/>

                            <h4>Observações:(opcional)</h4>
                            <div className="observacoes" rows="4" cols="50" name="comment">
                                <textarea id="observacao1"></textarea>
                            </div>
                            <Resumo
                                price={price}
                                sabor01={sabor01}
                                sabor02={sabor02}
                                size={size}
                                border={border}
                                tipo={tipo}
                            />

                            <Button02 value="Adicionar ao Pedido" action={(e)=>includeOnCart(e)} btn_state={btn_state} />

                            <Credito />
                        </>)
                        :(<></>)}
                    </>)
                    :<></>}
                    </>)
                    :tipo === 'Inteira'
                    ?(<>
                    <h4>Defina o sabor.</h4>
                    <Sabor action={openPizzas} sabor01={sabor01.sabor} sabor02={sabor02.sabor}/>
                    {sabor01.sabor !== 'Escolha o primeiro sabor'
                    ?(<>
                        <h4>Defina o Tamanho.</h4>
                        <ChooseDuo value01='Grande' value02='Broto' name='tamanho' action={setarSize}/>
                        {size != null
                        ?(<>
                            <h4>Defina o tipo de borda.</h4>
                            <Select setarBorda={setarBorda} colecaoBorder={colecaoBorder}/>

                            <h4>Observações:(opcional)</h4>
                            <div className="observacoes" rows="4" cols="50" name="comment">
                                <textarea id="observacao2"></textarea>
                            </div>
                            <Resumo
                                price={price}
                                sabor01={sabor01}
                                sabor02={sabor02}
                                size={size}
                                border={border}
                                tipo={tipo}
                            />

                            <Button02 value="Adicionar ao Pedido" action={(e)=>includeOnCart(e)} btn_state={btn_state} />

                            <Credito />
                        </>)
                        :(<></>)}
                    </>)
                    :<></>}
                    </>)
                    :(<></>)}

                </div>

                </div>
            </div>
        </div>
        )
    
}
export default PizzasDoces