import CardPizzas from "./CardPizzas"
import { useState, useEffect } from 'react';
import firebase from "firebase";

const ListaPizzas = ({selectPizza, l, colecao})=>{
    const db = firebase.firestore();
    const [pizzas, setPizzas] = useState([]);

    useEffect(()=>{
        db.collection(colecao).orderBy('saborPizza').onSnapshot(function(snapshot){
            setPizzas(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])
  
    const avaliablePizzas = pizzas.filter(pizzas => pizzas.data.disponibilidade === true)

    return(
        <div className="lista_pizzas">
            <h4>Pizzas Salgadas</h4>
            {avaliablePizzas.map((val)=>{
                  return(
                    <CardPizzas
                        key={val.id} 
                        selectPizza={(e)=>selectPizza(e,val.data.saborPizza, l, val.data.valorPizzaGrande, val.data.valorPizzaBroto)}
                        nome={val.data.saborPizza}
                        ingredientes={val.data.ingredientesPizza}
                        valorG={val.data.valorPizzaGrande}
                        valorP={val.data.valorPizzaBroto}
                    />
                  )
                })}
            
        </div>
    )
}
export default ListaPizzas