import Header from "../layout/Header"
import {Link} from 'react-router-dom'
import Enviado from './../img/sent.png'
import Recebido from './../img/received.png'
import Delivery from './../img/delivery.png'
import Preparando from './../img/coocking.png'
import Recusado from './../img/recusado.png'
import Progress from "./components/Progress"
import firebase from "firebase";
import { useEffect,useState } from 'react';
import AcompanharBottom from './components/AcompanharBottom'
import Button02 from "./components/Button02"

const Acompanhar = ({uid})=>{

    const db = firebase.firestore()
    const [pedidos, setPedidos] = useState([]);

    useEffect(()=>{       
        db.collection('pedidos').where("idCliente","==",uid).onSnapshot(function(snapshot){
            setPedidos(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

        const pedidoAtual = pedidos.filter(pedidos =>
        pedidos.data.statusPedido === 'enviado'
        || pedidos.data.statusPedido === 'recebido'
        || pedidos.data.statusPedido === 'preparando'
        || pedidos.data.statusPedido === 'saiu para entrega'
        || pedidos.data.statusPedido === 'pedido recusado')

    return (
        <div>
            <div className="clear_background">
            <div className="clear_background_container">
                <Header uid={uid}/>
                <div className="container_comum">
                    <div className="container_acompanhar">
                        <div className="tittle_01">
                            <Link to="/pedido"><i className="bi bi-arrow-left-circle-fill"></i></Link>
                            <h5 style={{color:'#442200'}}><i className="bi bi-clock-history"></i> Acompanhe seu Pedido</h5>
                            <div style={{marginLeft:'30px'}}></div>
                        </div>
                        {pedidoAtual.length > 0
                        ?(<>
                        {pedidoAtual.map((val)=>{
                            return(val.data.statusPedido === 'enviado' || val.data.statusPedido === 'recebido' || val.data.statusPedido === 'preparando' || val.data.statusPedido === 'saiu para entrega'
                                ?(<div key={val.id}>
                                    <div className="acompanhar_number">
                                        <h3>Número do Pedido: {val.data.numeroPedido}</h3>
                                    </div>
                                    <div className="acompanhar_chart">
                                    <Progress and={val.data.statusPedido}/>
                                    <div className="acompanhar_event">
                                        {val.data.statusPedido === 'enviado' ?
                                        (<img alt="" src={Enviado} />)
                                        : val.data.statusPedido === 'recebido' ?
                                        (<img alt="" src={Recebido} />)
                                        : val.data.statusPedido === 'preparando' ?
                                        (<img alt="" src={Preparando} />):
                                        (<img alt="" src={Delivery} />)}
                                    </div>
                                    </div>
                                    
                                    <div className="acompanhar_status"><h2>{val.data.statusPedido} - R${(val.data.total).toFixed(2)}</h2></div>
                                    <AcompanharBottom status={val.data.statusPedido}/>
                                    </div>)
                                :val.data.statusPedido === 'pedido recusado'
                                ?(<div key={val.id}>
                                    <div className="acompanhar_number">
                                        <h3>Número do Pedido: {val.data.numeroPedido}</h3>
                                    </div>
                                    <div className="acompanhar_chart">
                                    <div className="acompanhar_event">
                                        <img alt="" src={Recusado} />
                                    </div>
                                    </div>
                                    
                                    <div className="acompanhar_status"><h2>{val.data.statusPedido}
                                    <br/>Entre em contato pelo WhatsApp
                                    </h2></div>
                                    </div>)
                                :(<></>)
                            )
                        })}
                        </>)
                        :(<div>
                            <h4 style={{marginTop:'150px',textAlign:'center'}}>Não há pedidos em andamento!</h4>
                            <Button02 value="Continuar comprando" to='/pedido' />
                        </div>)}
                          
                        

                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Acompanhar