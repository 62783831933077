import Header from "../layout/Header"
import Button02 from "./components/Button02"
import CardPanda from "./components/CardPanda"

const Pedido = ({uid})=>{
    
    return (
        
        <div className="clear_background">
            <div className="clear_background_container">
                <Header uid={uid} />
                <div className="container_comum">
                <div className="title">
                    <h3>O que vai ser hoje?</h3>
                </div>
                <div className="container_pedidos">
                    <CardPanda
                        produto='Pizzas Salgadas'
                        linha01='+ de 40 sabores'
                        linha02='à partir de R$29,90'
                        estilo='01'
                        direction='/pizzas-salgadas'
                    />
                    <CardPanda
                        produto='Pizzas Doces'
                        linha01='+ de 10 sabores'
                        linha02='à partir de R$39,90'
                        estilo='02'
                        direction='/pizzas-doces'
                    />
                    <CardPanda
                        produto='Combos e Promoções'
                        linha01='Economize e saboreie'
                        linha02='Promoção especial'
                        estilo='03'
                        direction='/combos-promo'
                    />
                    <CardPanda
                        produto='Bebidas'
                        linha01='Melhores marcas'
                        linha02='Tudo vem bem gelado'
                        estilo='04'
                        direction='/bebidas'
                    />

                    <Button02 value="Finalizar o Pedido" to='/finalizar-pedido' btn_state={false}/>
                </div>
                </div>
            </div>
        </div>    
    )
}

export default Pedido