const QuantidadeBebida = ({par, cart})=>{

      const produtoExiste = cart.filter(cart => cart.data.produto === par)

      if(produtoExiste.length > 0){
        var quantidade = produtoExiste[0].data.quantidade;
      }else{
        var quantidade = 0;
      }
      

    return (
        <>
        {quantidade}
        </>
        )
}

export default QuantidadeBebida