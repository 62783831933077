import Button01 from './Button01'

const ListaCombo = (props)=>{
    return(
        <>
        <div className="lista_combo">
            <div className="tittle_01" style={{color:'white'}}>
                <div style={{marginRight:'36px'}}></div><h4>{props.nome}</h4>
                <div onClick={()=>props.closeModal(props.comboId)}>
                    <i className="bi bi-x-circle-fill"  style={{color:'white', marginRight:'15px'}}></i>
                </div>
            </div>
            <div className="image_combo">
                <img alt='' src={props.img} />
            </div>
            <div className="texto_combo">
                <p>{props.info}</p>
                <p><b>R${(props.valor).toFixed(2)}</b></p>
            </div>
        </div>
        <Button01 value="Adicionar ao Pedido" action={props.action} nome={props.nome} valor={props.valor} btn_state={props.btn_state}/>
        </>
        
    )
}

export default ListaCombo