const Resumo = (props)=>{

    var sabor01 = props.sabor01.sabor
    var sabor02 = props.sabor02.sabor
    
    return(
        <div className="resumo">
            {sabor01 !== 'Escolha o primeiro sabor'
            ?(<>
            {props.tipo === 'Inteira' & sabor01 !== 'Escolha o primeiro sabor'
            ?(<>
            <div className="container_resumo">
                <h3>Pizza {sabor01}</h3>
                <p>{props.size}
                {props.border !== 'Simples'
                ?(<> + Borda de {props.border}</>)
                :(<></>)}
                </p>
                <h4>Valor R${(props.price).toFixed(2)}</h4>
            </div>
            </>)
            :props.tipo === 'Meio a Meio' & sabor01 !== 'Escolha o primeiro sabor' & sabor02 !== 'Escolha o segundo sabor'
            ?(<>
                <div className="container_resumo">
                    <h3>Pizza Meia {sabor01} / Meia {sabor02}</h3>
                    <p>{props.size}
                    {props.border !== 'Simples'
                    ?(<> + Borda de {props.border}</>)
                    :(<></>)}
                    </p>
                    <h4>Valor R${(props.price).toFixed(2)}</h4>
                </div>
              </>)
            :<><br/></>}</>)
            :(<></>)}
            
        </div>    
    )
}
export default Resumo