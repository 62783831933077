const CardCombo =(props)=>{
    return(
        <div className="card_combospromo"  onClick={props.action}>
            <div className="image_combospromo" style={{backgroundImage:`url(${props.img})`}}>
            </div>
            <div className="info_combospromo">
                <h3>{props.nome}</h3>
                <p>{props.info}</p>
                <p><b>R${(props.valor).toFixed(2)}</b></p>
            </div>
        </div>
    )
}

export default CardCombo