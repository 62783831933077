const Sabores = ({action, sabor01, sabor02})=>{
    return(
        <div className="sabores">
			<div className="sabor" onClick={()=>action(1)}>
				<h4>{sabor01}</h4><span>+</span>
			</div>
		    <div className="sabor" onClick={()=>action(2)}>
				<h4>{sabor02}</h4><span>+</span>
		    </div>
		</div>
    )
}
export default Sabores