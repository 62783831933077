import Pizza01 from './../../img/pizza_01.jpg'

const CardPizzas = (props)=>{
    return(
        <div className="cardpizzas" onClick={props.selectPizza}>
            <div className="imagem_fundo">
             <img alt="" src={Pizza01} />
            </div>
            
            <div className="over_card_pizzas">
                <h4>{props.nome}</h4>
                <p>G R${props.valorG} - B R${props.valorP}<br/>{props.ingredientes}</p>
            </div>
        </div>
    )
}

export default CardPizzas