import { useState, useEffect } from 'react';
import firebase from "firebase";

const Select =({setarBorda, colecaoBorder})=>{

	const db = firebase.firestore();
    const [border, setBorder] = useState([]);

    useEffect(()=>{
        db.collection(colecaoBorder).orderBy('valorBorda','asc').onSnapshot(function(snapshot){
            setBorder(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        })
	
	const definirBorda = ()=>{
		let value = document.getElementById('borda').value
		setarBorda(value)
	}
	
	return(
        <div className="select_options">
			<select id="borda" name="borda" onChange={definirBorda}>
			{border.map((val)=>{
				return(
						<option key={val.id} value={val.data.recheio}>{val.data.recheio} - R${(val.data.valorBorda).toFixed(2)}</option>
					)
			})}
			</select>
		</div>
    )
}
export default Select