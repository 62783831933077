import Header from "../layout/Header"
import {Link} from 'react-router-dom'
import Refri from './../img/drink_01.jpg'
import Cerveja from './../img/drink_02.jpg'
import Outros from './../img/drink_03.jpg'
import { IoMdBeer } from 'react-icons/io';
import firebase from "firebase";
import { useState, useEffect } from 'react';
import QuantidadeBebida from "./components/QuantidadeBebida"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button02 from "./components/Button02"
import Credito from "./components/Credito"

const Drinks = ({uid})=>{

    const db = firebase.firestore();
    const [drinks, setDrinks] = useState([]);
    const [cart, setCart] = useState([]);

    useEffect(()=>{          
        db.collection('bebidas').onSnapshot(function(snapshot){
            setDrinks(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        db.collection('users').doc(uid).collection('carrinho').onSnapshot(function(snapshot){
            setCart(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

        const avaliableDrinks = drinks.filter(drinks => drinks.data.disponibilidade === true)

        const refrigerantes = avaliableDrinks.filter(drinks => drinks.data.tipoBebida === 'refrigerante')
        const cervejas = avaliableDrinks.filter(drinks => drinks.data.tipoBebida === 'cerveja')
        const outros = avaliableDrinks.filter(drinks => drinks.data.tipoBebida === 'outro')
      
        const includeOnCart = (e, par) => {
            e.preventDefault();
            
            let drink = drinks.filter(drinks => drinks.id === par)
            let produto = drink[0].data.nomeBebida;
            let price = drink[0].data.valor;
            
            let produtoExiste = cart.filter(cart => cart.data.produto === produto)
            
            if(produtoExiste.length > 0){
              let cId = produtoExiste[0].id;
              db.collection('users').doc(uid).collection('carrinho').doc(cId).update({
                quantidade: firebase.firestore.FieldValue.increment(1),
            }).then(()=> toast.success(produto+' inserido no pedido!'))
            }else{
              db.collection('users').doc(uid).collection('carrinho').add({
                produto: produto,
                quantidade: +1,
                valor: price,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              }).then(()=> toast.success(produto+' inserido no pedido!'))
            }
            /**/    
          }
          const deleteFromCart = (e, par) => {
            e.preventDefault();
            
            let drink = drinks.filter(drinks => drinks.id === par)
            let produto = drink[0].data.nomeBebida;
            
            let produtoExiste = cart.filter(cart => cart.data.produto === produto)
            
            if(produtoExiste.length > 0 && produtoExiste[0].data.quantidade > 1){
              let cId = produtoExiste[0].id;
              db.collection('users').doc(uid).collection('carrinho').doc(cId).update({
                quantidade: firebase.firestore.FieldValue.increment(-1),
            })
            }else if(produtoExiste.length > 0 && produtoExiste[0].data.quantidade === 1){
              let cId = produtoExiste[0].id;
              db.collection('users').doc(uid).collection('carrinho').doc(cId).delete()
            }else{
              console.log('Tentou deletar produto que não existe')
            }
            
            /**/    
          }

    return(
        <div className="clear_background">
            <ToastContainer theme="dark" position="top-center" />
            <div className="clear_background_container">
                <Header uid={uid}/>
                <div className="container_comum">
                    <div className="combos_promo">
                    
                    <div className="tittle_01">
                        <Link to="/pedido"><i className="bi bi-arrow-left-circle-fill"></i></Link>
                        <h4><IoMdBeer /> Bebidas</h4>
                        <div style={{marginLeft:'40px'}}></div>
                    </div>

                    <div className="container_drinks">
                        
                        <div className="card_drinks">
                         <div className="tittle_drinks">
                            <img alt="" src={Refri} />
                            <h4>Refrigerantes</h4>
                         </div>
                         
                        <div className="list_drinks">
                            {refrigerantes.map((val)=>{
                                return(
                                    <div className="line_drinks" key={val.id}>
                                        <div className="line_drinks_01" id={'produto'+val.id}>{val.data.nomeBebida}</div>
                                        <div className="line_drinks_02">R${(val.data.valor).toFixed(2)}</div>
                                        <div className="line_drinks_03">
                                            <i className="bi bi-plus-circle-fill" onClick={(e)=>includeOnCart(e,val.id)}></i>
                                            <div style={{textAlign:'center'}}>
                                            {cart.length > 0 ?
                                                (<QuantidadeBebida par={val.data.nomeBebida} cart={cart}/>)
                                                :
                                                ('0')
                                                }
                                            </div>
                                            <i className="bi bi-dash-circle-fill" onClick={(e)=>deleteFromCart(e,val.id)}></i>
                                        </div>
                                    </div>
                                )
                            })}              
                        </div>
                        </div>

                        <div className="card_drinks">
                         <div className="tittle_drinks">
                            <img alt="" src={Cerveja} />
                            <h4>Cervejas</h4>
                         </div>
                         
                        <div className="list_drinks">
                        {cervejas.map((val)=>{
                                return(
                                    <div className="line_drinks" key={val.id}>
                                        <div className="line_drinks_01" id={'produto'+val.id}>{val.data.nomeBebida}</div>
                                        <div className="line_drinks_02">R${(val.data.valor).toFixed(2)}</div>
                                        <div className="line_drinks_03">
                                            <i className="bi bi-plus-circle-fill" onClick={(e)=>includeOnCart(e,val.id)}></i>
                                            <div style={{textAlign:'center'}}>
                                            {cart.length > 0 ?
                                                (<QuantidadeBebida par={val.data.nomeBebida} cart={cart}/>)
                                                :
                                                ('0')
                                                }
                                            </div>
                                            <i className="bi bi-dash-circle-fill" onClick={(e)=>deleteFromCart(e,val.id)}></i>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        </div>

                        <div className="card_drinks">
                         <div className="tittle_drinks">
                            <img alt="" src={Outros} />
                            <h4>Outros</h4>
                         </div>
                         
                        <div className="list_drinks">
                        {outros.map((val)=>{
                                return(
                                    <div className="line_drinks" key={val.id}>
                                        <div className="line_drinks_01" id={'produto'+val.id}>{val.data.nomeBebida}</div>
                                        <div className="line_drinks_02">R${(val.data.valor).toFixed(2)}</div>
                                        <div className="line_drinks_03">
                                            <i className="bi bi-plus-circle-fill" onClick={(e)=>includeOnCart(e,val.id)}></i>
                                            <div style={{textAlign:'center'}}>
                                            {cart.length > 0 ?
                                                (<QuantidadeBebida par={val.data.nomeBebida} cart={cart}/>)
                                                :
                                                ('0')
                                                }
                                            </div>
                                            <i className="bi bi-dash-circle-fill" onClick={(e)=>deleteFromCart(e,val.id)}></i>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        </div>

                        <Button02 value="Finalizar o Pedido" to="/finalizar-pedido" />
                        <Button02 value="Continuar Comprando" to="/pedido" />

                    </div>
                    <Credito />            
                    </div>               
                </div>
            </div>
        </div>
        )
    
}
export default Drinks